import React from 'react';

import SubpageLayout from "../components/subpage-layout"
import Seo from "../components/seo"

const Contact = () => (
   <SubpageLayout>
      <Seo title="Contact" />
      <header className="subpage--content-header">
        <h1>Contact</h1>
      </header>

      <section>
        <p>
          <strong>Diana Keane</strong><br />
          <i>Project Manager</i><br />
          <a href="tel:803-799-9084">803-799-9084</a> &nbsp;|&nbsp;
          <a href="mailto:intercept@richlandlibrary.com">Email</a>
        </p>

        <p>
          <strong>Richland Library Main</strong><br />
          1431 Assembly St.<br />
          Columbia, SC 29201<br />
        </p>
      </section>
   </SubpageLayout>

)

export default Contact